import classnames from 'classnames';
import React, {useRef, useEffect} from 'react';
import {DotNavigation} from 'wix-ui-tpa/cssVars';
import {Experiments} from '../../../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {classes as sliderGalleryStyleable} from './PaginationDots.st.css';
import s from './PaginationDots.scss';

export interface PaginationDotsProps {
  swiperCurrentIndex: number;
  totalProducts: number;
  slidesCount: number;
  shouldLoop: boolean;
}

export enum PaginationDotsDataHooks {
  PaginationDots = 'PaginationDotsDataHooks.Dots',
  PaginationDotsWrapper = 'PaginationDotsDataHooks.Wrapper',
}
const LOOPING_MIN_DOTS_AMOUNT = 7;
const CENTER_DOT_INDEX = 3;

export const PaginationDots = ({swiperCurrentIndex, totalProducts, slidesCount, shouldLoop}: PaginationDotsProps) => {
  const {experiments} = useExperiments();
  const rootRef = useRef<HTMLDivElement>(null);
  const showPaginationDotsCustomStyle = experiments.enabled(Experiments.ShowPaginationDotsCustomStyle);
  const shouldHideDots = showPaginationDotsCustomStyle && totalProducts <= slidesCount;
  const dotsAmount =
    shouldLoop && showPaginationDotsCustomStyle ? Math.max(LOOPING_MIN_DOTS_AMOUNT, totalProducts) : totalProducts;
  const dotIndex =
    totalProducts < 5 && shouldLoop && showPaginationDotsCustomStyle ? CENTER_DOT_INDEX : swiperCurrentIndex;

  // istanbul ignore next: cant test with jsdom, tested by sled
  useEffect(() => {
    const rootWidth = rootRef?.current?.clientWidth;
    if (!rootWidth) {
      return;
    }
    setTimeout(() => rootRef.current?.classList.remove(s.hideDotsOnSSR), 0);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rootRef, rootRef?.current, rootRef?.current?.clientWidth]);

  return (
    <div
      data-hook={PaginationDotsDataHooks.PaginationDotsWrapper}
      role="navigation"
      ref={rootRef}
      className={classnames(s.dotNavigationWrapper, {
        [s.paginationDotsCustomStyle]: showPaginationDotsCustomStyle,
        [s.showDots]: !shouldHideDots,
        [s.hideDotsOnSSR]: showPaginationDotsCustomStyle,
        [sliderGalleryStyleable.root]: showPaginationDotsCustomStyle,
      })}>
      <DotNavigation
        className={'pagination-dots'}
        aria-label={swiperCurrentIndex.toString()}
        data-hook={PaginationDotsDataHooks.PaginationDots}
        currentIndex={dotIndex}
        length={dotsAmount}
        showBorder={showPaginationDotsCustomStyle}
      />
    </div>
  );
};
